import React, { useEffect, useMemo } from 'react';
import { useRoutes, useNavigate } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { userStore } from 'libModule/userStore';
import { modalStore } from 'libModule/modalStore';
import { LocalStorage } from 'libModule/LocalStorage';
import { useSessionStorage } from 'libModule/useSessionStorage';
import { fetchWithAuth } from 'libModule/fetchWithAuth';
import { useSyncUser } from 'libModule/useSyncUser';
import { getENV } from 'libModule/getENV';
import { isNeedRedirectUser } from 'libModule/isNeedRedirectUser';

import { routes } from './Routs.jsx';
import Preloader from 'uiModule/Preloader';
import UpdateOrgTypeAndRoleModal from 'uiModule/UpdateOrgTypeAndRoleModal';
import { storeHubspotCookies } from './helpers/storeHubspotCookies.js';
import { useSmartlookForNewUser } from './hooks/useSmartlookForNewUser.js';
import { ToastProvider } from 'uiModule/ToastProvider';
import * as Sentry from '@sentry/react';

const App = observer(() => {
  const { modalProps } = modalStore;
  let { host, pathname, search } = window.location;

  const redirectPathnames = {
    'platform.gogipper.com': {
      templates: pathname.replace('templates', 'graphics'),
      preset_folders: pathname.replace('preset_folders', 'designs'),
      scheduled_posts: '/planner',
      nl_graphic_editor: pathname.replace(
        'nl_graphic_editor',
        'graphics/header'
      ),
    },
  };

  let redirectedPath =
    redirectPathnames[host] && redirectPathnames[host][pathname.split('/')[1]];

  if (redirectedPath) {
    const redirectURL = `https://platform.gogipper.com${redirectedPath}${search}`;
    window.location.replace(redirectURL);
  }

  const navigate = useNavigate();
  const currentUser = userStore.userInfo;
  const userToken = LocalStorage.get('auth_token');

  const [, , metaSession] = useSessionStorage(
    'onboardingMeta',
    userStore.onboardingMeta
  );

  const singleDropDown =
    currentUser?.workspace?.organization_type || currentUser?.organization_role;
  const meta = { onboarding: metaSession.get() || userStore.onboardingMeta };

  const currentURL = window.location.href;
  const currentURLpathname = window.location.pathname;
  const currentURLsearchParams = window.location.search;

  const runSmartlookForNewUser = useSmartlookForNewUser();

  const excludedPaths = [
    '/login',
    '/sign_up',
    '/edit_invited_user',
    '/forgot_password',
    '/reset_password',
    '/subscriptions',
    '/plans',
  ];

  const isExcluded = excludedPaths.some((path) => currentURL?.includes(path));

  useEffect(() => {
    const fetchReleaseVersions = async () => {
      const response = await fetch(
        `https://${getENV.VITE_API_SERVER}/api/release_versions`,
        { cache: 'no-store' }
      );
      const result = await response.json();
      LocalStorage.set('releaseVersionsApi', result.version_number);
    };

    fetchReleaseVersions();
    storeHubspotCookies();
  }, []);

  useSyncUser();

  useEffect(() => {
    if (userToken) {
      isNeedRedirectUser(
        navigate,
        userToken,
        currentUser,
        currentURLpathname,
        currentURLsearchParams
      );

      fetchWithAuth(
        `https://${getENV.VITE_API_SERVER}/api/${getENV.VITE_API_VERSION}/users/pendo`
      ).then((result) => {
        window.pendo.initialize({
          visitor: result.visitor,
          account: result.account,
        });
        runSmartlookForNewUser(result.visitor);
      });
    } else {
      !isExcluded && LocalStorage.set('redirect_page', currentURL);
      isNeedRedirectUser(
        navigate,
        userToken,
        currentUser,
        currentURLpathname,
        currentURLsearchParams
      );
    }
  }, [userToken, runSmartlookForNewUser]);

  useEffect(() => {
    Sentry.setUser(
      currentUser
        ? {
            id: currentUser.id,
            email: currentUser.email,
            name: currentUser.name,
          }
        : {
            id: 'unloginedUser',
            email: 'unloginedUser',
            name: 'unloginedUser',
          }
    );
    // monitoring users theme
    if (currentUser && userToken) {
      const selectedTheme =
        currentUser?.mode === 'dark' ? 'darkMode' : 'lightMode';
      const appliedTheme = document.documentElement.dataset.mode;
      if (selectedTheme !== appliedTheme) {
        document.documentElement.dataset.mode = selectedTheme;
      }
    } else {
      document.documentElement.dataset.mode = '';
    }
  }, [currentUser, userToken]);

  const isUpdateOrgTypeOrRole = useMemo(() => {
    const isStripeUserRegistration = LocalStorage.get('isStripeUser');
    const isOnboardingProcess = !!meta.onboarding;
  
    if (isOnboardingProcess || isStripeUserRegistration) {
      return false;
    } 

    const isCreator = !(userStore.userInfo.workspace_role?.name === 'owner');

    if (
      (!currentUser.workspace?.organization_type &&
        currentUser?.workspace_role?.name === 'owner') ||
      (!currentUser?.organization_role &&
        !currentUser?.custom_organization_role &&
        currentUser?.workspace_role?.name === 'owner') ||
      (isCreator &&
        currentUser.workspace?.organization_type &&
        !currentUser?.organization_role &&
        !currentUser?.custom_organization_role) ||
      currentUser?.need_update_organization_type
    ) {
      return true;
    } else {
      return false;
    }
  }, [singleDropDown, modalProps, currentUser, meta]);

  const onSubmitSuperAdminsOrgType = (body) => {
    fetchWithAuth(
      `https://${getENV.VITE_API_SERVER}/api/${getENV.VITE_API_VERSION}/users`,
      'PUT',
      body
    ).then(() => {
      userStore.fetchUserInfo();
    });
  };

  const routing = useRoutes(routes(userToken, currentUser, meta));

  if (userToken && !userStore.userInfo?.id) {
    return <Preloader active />;
  }

  return (
    <>
      <React.Suspense fallback={<Preloader active={true} />}>
        <ToastProvider>{routing}</ToastProvider>
      </React.Suspense>
      {isUpdateOrgTypeOrRole && (
        <UpdateOrgTypeAndRoleModal
          initialUserParams={currentUser}
          initialWsParams={currentUser.workspace}
          subtitle={`Let's complete your profile by answering ${
            singleDropDown ? 'the following question' : 'a couple questions'
          }.`}
          onClose={() => {}}
          onUpdate={(body) => onSubmitSuperAdminsOrgType(body)}
          isShowCloseButton={false}
        />
      )}
    </>
  );
});

export default App;
