import ReactDOM from 'react-dom/client';
import * as Sentry from '@sentry/react';
import App from './App.jsx';
import { BrowserRouter as Router } from 'react-router-dom';
import { isProd } from 'libModule/isProd';
import { getENV } from 'libModule/getENV';
import { gipperGeneralStyles } from 'uiModule/gipperGeneralStyles';
import packageJson from '../package.json';

document.head.insertAdjacentHTML('afterbegin', gipperGeneralStyles);

const { version } = packageJson;

const S3_BASE_URL = 'https://dzfa1uifb0sb6.cloudfront.net/build-store';

const MODULE_PATHS = {
  'ac-module': `${S3_BASE_URL}/ac-module/v${version}/assets`,
  'lib-module': `${S3_BASE_URL}/lib-module/v${version}/assets`,
  'proxy-module': `${S3_BASE_URL}/proxy-module/v${version}/assets`,
  'ui-module': `${S3_BASE_URL}/ui-module/v${version}/assets`,
  'gl-module': `${S3_BASE_URL}/gl-module/v${version}/assets`,
  'nl-editor-module': `${S3_BASE_URL}/nl-editor-module/v${version}/assets`,
  'gr-editor-module': `${S3_BASE_URL}/gr-editor-module/v${version}/assets`,
  'pl-module': `${S3_BASE_URL}/pl-module/v${version}/assets`,
  'ws-module': `${S3_BASE_URL}/ws-module/v${version}/assets`,
};

isProd() &&
  Sentry.init({
    dsn: 'https://63ed7736ab19ae6fa3a8f9a786fb8430@o4505885323231232.ingest.us.sentry.io/4508964280205312',
    integrations: [Sentry.browserTracingIntegration()],
    beforeSend: (event, hint) => {
      if (hint.originalException?.name === 'InterruptError') {
        return null;
      }
      return event;
    },
    enableTracing: false,
    tracesSampleRate: 0,
    maxBreadcrumbs: 10,
    release: version,
    rewriteFrames: {
      iteratee: (frame) => {
        if (frame.filename) {
          let moduleFound = false;
          for (const [moduleName, modulePath] of Object.entries(MODULE_PATHS)) {
            if (
              frame.filename.includes(`/${moduleName}/`) ||
              (frame.filename.includes(`remoteEntry.js`) &&
                moduleName !== 'main-module')
            ) {
              frame.filename = `${modulePath}/${frame.filename
                .split('/')
                .pop()}`;
              moduleFound = true;
              break;
            }
          }

          if (!moduleFound && frame.filename.startsWith('/')) {
            frame.filename = `${MODULE_PATHS['main-module']}${frame.filename}`;
          }
        }
        return frame;
      },
    },
  });

// test
ReactDOM.createRoot(document.getElementById('root')).render(
  // <React.StrictMode>
  <Router>
    <App />
  </Router>
  // </React.StrictMode>
);
